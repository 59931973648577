import React from 'react';
import './Home.css'; // Import the CSS file


const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Electrical engineering</h1>
    
      <p>
        Electrical engineering is a dynamic and multifaceted field that focuses on the study and application of electricity, electronics, and electromagnetism. It encompasses a broad range of topics, from the design of electrical circuits and systems to the development of new technologies for power generation, transmission, and distribution. Electrical engineers work on a variety of projects, including the creation of electrical devices, control systems, telecommunications infrastructure, and renewable energy solutions. Their work is integral to modern society, impacting everything from the reliability of the electrical grid to the advancement of consumer electronics and innovative technologies. By combining theoretical knowledge with practical skills, electrical engineers address complex challenges and drive technological progress, making significant contributions to both industry and daily life.
      </p>
    </div>
  );
};

export default Home;
