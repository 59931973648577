import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase'; 
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore'; // deleteDoc is imported here
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminBlogEditor.css';

const AdminBlogEditor = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const subjects = ['Power System', 'Power Electronics', 'Machines','Control System', 'Embedded System'];

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogSnapshot = await getDocs(collection(db, 'blogs'));
      setBlogs(blogSnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      })));
    };

    fetchBlogs();
  }, []);

  const handleImageUpload = async (file) => {
    if (!file) return;

    const imageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(imageRef, file);
    const url = await getDownloadURL(imageRef);
    setImageUrl(url);
  };

  const handleSaveBlog = async () => {
    try {
      const blogRef = selectedBlog ? doc(db, 'blogs', selectedBlog.id) : doc(collection(db, 'blogs'));
      await setDoc(blogRef, { title, content, subject, imageUrl, createdAt: new Date() });
      alert('Blog saved successfully!');
      setTitle('');
      setContent('');
      setSubject('');
      setImageUrl('');
      setSelectedBlog(null);
      const blogSnapshot = await getDocs(collection(db, 'blogs'));
      setBlogs(blogSnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      })));
    } catch (error) {
      console.error('Error saving blog:', error);
    }
  };

  // Define the handleDeleteBlog function
  const handleDeleteBlog = async (id) => {
    try {
      await deleteDoc(doc(db, 'blogs', id)); // Delete the blog from Firestore
      setBlogs(blogs.filter((blog) => blog.id !== id)); // Update the state after deletion
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  return (
    <div>
      <h3>{selectedBlog ? 'Edit Blog' : 'Add New Blog'}</h3>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Blog Title"
      />
      <ReactQuill
        value={content}
        onChange={setContent}
        placeholder="Write your blog content here..."
        theme="snow"
      />
      <select value={subject} onChange={(e) => setSubject(e.target.value)}>
        <option value="" disabled>Select Subject</option>
        {subjects.map((subj) => (
          <option key={subj} value={subj}>{subj}</option>
        ))}
      </select>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files[0];
          handleImageUpload(file);
        }}
      />
      {imageUrl && <img src={imageUrl} alt="Uploaded preview" style={{ maxWidth: '100%' }} />}
      <button onClick={handleSaveBlog}>
        {selectedBlog ? 'Update Blog' : 'Add Blog'}
      </button>

      <h2>Existing Blogs</h2>
      <ul>
        {blogs.map((blog) => (
          <li key={blog.id}>
            <div className="blog-item">
              <span className="serial-no">{blog.serialNo}. </span>
              <h4>{blog.title} ({blog.subject})</h4>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              {blog.imageUrl && <img src={blog.imageUrl} alt={blog.title} style={{ maxWidth: '100px' }} />}
              <button onClick={() => {
                setSelectedBlog(blog);
                setTitle(blog.title);
                setContent(blog.content);
                setSubject(blog.subject);
                setImageUrl(blog.imageUrl);
              }}>Edit</button>
              <button onClick={() => handleDeleteBlog(blog.id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminBlogEditor;
