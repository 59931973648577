// RightSidebar.js
import React, { useEffect } from 'react';
import './RightSidebar.css';

const RightSidebar = () => {
  useEffect(() => {
    // Load Google Ads script when the component mounts
    const loadAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("Ad load error:", e);
      }
    };

    loadAds();
  }, []);

  return (
    <div className="right-sidebar">
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-9745428228335345"
           data-ad-slot="6923937002"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
};

export default RightSidebar;
