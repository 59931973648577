// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'; // Add this import for Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyCmpGDHakOtImGDJKRbKb7FagaDuODGO78",
    authDomain: "ajay-3f430.firebaseapp.com",
    projectId: "ajay-3f430",
    storageBucket: "ajay-3f430.appspot.com",
    messagingSenderId: "160229737391",
    appId: "1:160229737391:web:04db73a1a4d4660df8255a",
    measurementId: "G-6E9HWL3KQW"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage
const firestore = getFirestore(app);
export { db, auth, storage, firestore};
