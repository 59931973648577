import React, { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust import path as needed

const AdminCoursesEditor = () => {
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const coursesCollection = collection(db, 'courses');
        const courseSnapshot = await getDocs(coursesCollection);

        if (courseSnapshot.empty) {
          console.log('No courses found');
          setCourses([]);
          setLoading(false);
          return;
        }

        const coursesList = courseSnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setCourses(coursesList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError('Failed to load courses.');
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleSaveCourse = async () => {
    if (!title || !description) {
      alert('Please fill in both the title and description.');
      return;
    }

    try {
      const sanitizedTitle = title.trim();
      const sanitizedDescription = description.trim();

      if (!sanitizedTitle || !sanitizedDescription) {
        alert('Invalid input. Please provide valid title and description.');
        return;
      }

      const courseRef = selectedCourse
        ? doc(db, 'courses', selectedCourse.id)
        : doc(collection(db, 'courses'));

      await setDoc(courseRef, {
        title: sanitizedTitle,
        description: sanitizedDescription,
        updatedAt: new Date(),
      });

      alert('Course saved successfully!');
      setTitle('');
      setDescription('');
      setSelectedCourse(null);

      const coursesCollection = collection(db, 'courses');
      const courseSnapshot = await getDocs(coursesCollection);
      const updatedCoursesList = courseSnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      }));
      setCourses(updatedCoursesList);
    } catch (error) {
      console.error('Error saving course:', error);
      alert('Failed to save course.');
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      await deleteDoc(doc(db, 'courses', id));
      alert('Course deleted successfully!');

      setCourses(courses.filter((course) => course.id !== id));
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Failed to delete course.');
    }
  };

  const handleEditCourse = (course) => {
    setTitle(course.title);
    setDescription(course.description);
    setSelectedCourse(course);
  };

  if (loading) {
    return <div>Loading courses...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h3>{selectedCourse ? 'Edit Course' : 'Add New Course'}</h3>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Course Title"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Course Description"
      />
      <button onClick={handleSaveCourse}>
        {selectedCourse ? 'Update Course' : 'Add Course'}
      </button>

      <h2>Existing Courses</h2>
      <ul>
        {courses.length === 0 ? (
          <li>No courses available</li>
        ) : (
          courses.map((course) => (
            <li key={course.id}>
              <h4>{course.serialNo}. {course.title}</h4>
              <p>{course.description}</p>
              <button onClick={() => handleEditCourse(course)}>Edit</button>
              <button onClick={() => handleDeleteCourse(course.id)}>Delete</button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default AdminCoursesEditor;
