import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust import path as needed
import './Courses.css'; // Import the CSS file

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [expandedCourseId, setExpandedCourseId] = useState(null); // State to manage expanded course
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesCollection = collection(db, 'courses');
        const coursesSnapshot = await getDocs(coursesCollection);
        
        if (coursesSnapshot.empty) {
          console.log('No courses found');
          return;
        }

        const coursesList = coursesSnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1, // Adding serial number based on index
          ...doc.data(),
        }));
        setCourses(coursesList);
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('Failed to load courses');
      }
    };

    fetchCourses();
  }, []);

  const handleTitleClick = (id) => {
    setExpandedCourseId(id === expandedCourseId ? null : id); // Toggle the expanded course
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="courses">
      <h1>Courses</h1>
      <ul className="courses-list">
        {courses.length === 0 ? (
          <li>No courses available</li>
        ) : (
          courses.map((course) => (
            <li key={course.id}>
              <div onClick={() => handleTitleClick(course.id)} className="course-title">
                {course.serialNo}. {course.title}
              </div>
              {expandedCourseId === course.id && (
                <div className="course-description">
                  {course.description}
                </div>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Courses;
