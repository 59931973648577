import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust import path as needed
import { useParams } from 'react-router-dom';

const CourseDetail = () => {
  const { subject } = useParams(); // Gets the subject from URL params
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        const docRef = doc(db, 'courses', subject);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setCourse(docSnap.data());
        } else {
          setError('Course not found');
        }
      } catch (err) {
        console.error('Error fetching course detail:', err);
        setError('Failed to load course detail');
      }
    };

    fetchCourseDetail();
  }, [subject]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{course.title}</h1>
      
      <p>{course.description}</p>
    </div>
  );
};

export default CourseDetail;
