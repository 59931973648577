import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './Blog.css'; // Import the CSS file

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [subjects] = useState(['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System']); // Predefined subjects

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(db, 'blogs');
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogList);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  // Filter blogs by subject
  const getBlogsBySubject = (subject) => {
    return blogs.filter(blog => blog.subject === subject);
  };

  return (
    <div className="blog-container">
      <h1>Blog Posts by Subject</h1>
      {subjects.map((subject) => (
        <div className="subject-section" key={subject}>
          <h2>{subject}</h2>
          {getBlogsBySubject(subject).length > 0 ? (
            getBlogsBySubject(subject).map((blog, index) => (
              <div className="blog-post" key={blog.id}>
                <h3>{index + 1}. {blog.title}</h3> {/* Serial number before blog title */}
                {blog.imageUrl && <img src={blog.imageUrl} alt={blog.title} />}
                <p>{blog.content}</p>
              </div>
            ))
          ) : (
            <p className="no-blogs">No blogs available for {subject}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Blog;
