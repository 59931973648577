import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firestore instance
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill'; // React-Quill for rich text editing
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import './AdminResearchEditor.css'; // Your custom CSS

const AdminResearchEditor = () => {
  const [researches, setResearches] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); // Rich-text content with React-Quill
  const [selectedResearch, setSelectedResearch] = useState(null); // Track selected research
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false); // Track if we are in editing mode

  useEffect(() => {
    const fetchResearch = async () => {
      try {
        const researchSnapshot = await getDocs(collection(db, 'research'));
        const fetchedResearch = researchSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResearches(fetchedResearch);
      } catch (err) {
        setError('Failed to load research.');
      } finally {
        setLoading(false);
      }
    };

    fetchResearch();
  }, []);

  const handleSaveResearch = async () => {
    if (!title || !content) {
      alert('Please fill in both the title and content.');
      return;
    }

    try {
      const sanitizedContent = DOMPurify.sanitize(content); // Sanitize the content

      const researchRef = selectedResearch
        ? doc(db, 'research', selectedResearch.id)
        : doc(collection(db, 'research'));

      await setDoc(researchRef, {
        title,
        content: sanitizedContent, // Save sanitized content
        createdAt: new Date(),
      });

      alert('Research saved successfully!');
      resetForm(); // Reset form after saving
      await fetchResearch(); // Re-fetch the research to update the list
    } catch (error) {
      console.error('Error saving research:', error);
      alert('Failed to save research.');
    }
  };

  const fetchResearch = async () => {
    const researchSnapshot = await getDocs(collection(db, 'research'));
    const fetchedResearch = researchSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setResearches(fetchedResearch);
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setSelectedResearch(null);
    setEditing(false); // Exit editing mode
  };

  const handleEditResearch = (research) => {
    setTitle(research.title);
    setContent(research.content || ''); // Set rich-text content for editing
    setSelectedResearch(research);
    setEditing(true); // Enter editing mode
  };

  const handleDeleteResearch = async (id) => {
    try {
      await deleteDoc(doc(db, 'research', id));
      setResearches(researches.filter((research) => research.id !== id));
      alert('Research deleted successfully!');
    } catch (error) {
      console.error('Error deleting research:', error);
      alert('Failed to delete research.');
    }
  };

  if (loading) {
    return <div>Loading research...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h3>{selectedResearch ? 'Edit Research' : 'Add New Research'}</h3>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Research Title"
      />
      
      <ReactQuill
        value={content}
        onChange={(value) => setContent(value)} // Handle rich text content change
        placeholder="Research Content"
        modules={{
          toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image', 'video'],
            ['clean'] 
          ],
        }}
      />

      <button onClick={handleSaveResearch}>
        {selectedResearch ? 'Update Research' : 'Add Research'}
      </button>

      {editing && <button onClick={resetForm}>Cancel</button>}

      <h2>Existing Research</h2>
      <ul>
        {researches.length === 0 ? (
          <li>No research available</li>
        ) : (
          researches.map((research, index) => (
            <li key={research.id}>
              <h4>{index + 1}. {research.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: research.content }} />
              <button onClick={() => handleEditResearch(research)}>Edit</button>
              <button onClick={() => handleDeleteResearch(research.id)}>Delete</button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default AdminResearchEditor;
