import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import './BlogDetail.css';
const BlogDetail = () => {
  const { subject } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogQuery = query(
          collection(db, 'blogs'),
          where('subject', '==', subject)
        );
        const blogSnapshot = await getDocs(blogQuery);
        const blogList = blogSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogList);
      } catch (err) {
        setError('Failed to fetch blogs');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [subject]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="blog-detail-container">
      <h1>{subject} Blogs</h1>
      {blogs.length === 0 ? (
        <p>No blogs available for {subject}</p>
      ) : (
        blogs.map((blog, index) => (
          <div key={blog.id} className="blog-post">
            <h2>{index + 1}. {blog.title}</h2>
            
            <div dangerouslySetInnerHTML={{ __html: blog.content }} /> {/* Render HTML content */}
            {blog.imageUrl && <img src={blog.imageUrl} alt={blog.title} />}
          </div>
        ))
      )}
    </div>
  );
};

export default BlogDetail;
