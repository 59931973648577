import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { getDocs, collection } from 'firebase/firestore';
import './Download.css';

const Download = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const fileDocs = await getDocs(collection(firestore, 'files'));
      const fileData = fileDocs.docs.map(doc => doc.data());
      setFiles(fileData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  return (
    <div className="download-container">
      <h1>Download Files</h1>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            <span>{index + 1}. </span> {/* Serial number */}
            <p><strong>Subject:</strong> {file.subject}</p>
            <a href={file.url} download>{file.title || file.url.split('/').pop()}</a>
            <p>{file.description}</p>
           
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Download;
