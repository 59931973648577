import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import RightSidebar from './components/RightSidebar'; // Only Right Sidebar
import Home from './pages/Home';
import Courses from './pages/Courses';
import Research from './pages/Research';
import Blog from './pages/Blog';
import MCQs from './pages/MCQs';
import Contact from './pages/Contact';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import Signup from './pages/Signup';
import CourseDetail from './pages/CourseDetail';
import MCQsBySubject from './pages/MCQsBySubject';
import BlogDetail from './pages/BlogDetail';
import Download from './pages/Download';
import './App.css';
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content-wrapper">
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:subject" element={<CourseDetail />} />
              <Route path="/research" element={<Research />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:subject" element={<BlogDetail />} />
              <Route path="/mcqs" element={<MCQs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/download" element={<Download />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/mcqs/:subjectName" element={<MCQsBySubject />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
          <RightSidebar /> {/* Only the right sidebar */}
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
