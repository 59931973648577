import React, { useState, useEffect } from 'react';
import { storage, firestore } from '../firebase';
import { ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import './ManageFiles.css'; 

const ManageFiles = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [editingFileName, setEditingFileName] = useState('');

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const fileDocs = await getDocs(collection(firestore, 'files'));
      const fileData = fileDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setFiles(fileData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const fileRef = ref(storage, `files/${file.name}`);
        await uploadBytes(fileRef, file);
        const fileDocRef = doc(firestore, 'files', file.name);
        await setDoc(fileDocRef, {
          title,
          description,
          subject,
          url: await getDownloadURL(fileRef)
        });
        fetchFiles();
        resetForm();
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleFileDelete = async (fileName) => {
    try {
      const fileRef = ref(storage, `files/${fileName}`);
      await deleteObject(fileRef);
      await deleteDoc(doc(firestore, 'files', fileName));
      fetchFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleFileEdit = (file) => {
    setEditingFileName(file.id);
    setTitle(file.title);
    setDescription(file.description);
    setSubject(file.subject);
  };

  const handleFileUpdate = async () => {
    try {
      const fileDocRef = doc(firestore, 'files', editingFileName);
      await setDoc(fileDocRef, {
        title,
        description,
        subject
      }, { merge: true });
      fetchFiles();
      resetForm();
    } catch (error) {
      console.error("Error updating file:", error);
    }
  };

  const resetForm = () => {
    setFile(null);
    setTitle('');
    setDescription('');
    setSubject('');
    setEditingFileName('');
  };

  return (
    <div>
      <h1>Manage Files</h1>
      <div>
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        {editingFileName ? (
          <button onClick={handleFileUpdate}>Update</button>
        ) : (
          <button onClick={handleFileUpload}>Upload</button>
        )}
      </div>

      <ul>
        {files.map((file, index) => (
          <li key={index}>
            <span>{index + 1}. </span> {/* Serial number */}
            <a href={file.url} download>{file.title || file.url.split('/').pop()}</a>
            <p>{file.description}</p>
            <p><strong>Subject:</strong> {file.subject}</p>
            <button onClick={() => handleFileDelete(file.id)}>Delete</button>
            <button onClick={() => handleFileEdit(file)}>Edit</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageFiles;
