import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Your Firebase config

const MCQs = () => {
  const [mcqs, setMcqs] = useState([]);
  const [filterSubject, setFilterSubject] = useState('');

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System', 'Aptitude'];

  useEffect(() => {
    const fetchMcqs = async () => {
      try {
        const mcqCollection = collection(db, 'mcqs');
        const mcqSnapshot = await getDocs(mcqCollection);
        const mcqList = mcqSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMcqs(mcqList);
      } catch (error) {
        console.error('Error fetching MCQs:', error);
      }
    };

    fetchMcqs();
  }, []);

  const handleFilterChange = (e) => {
    setFilterSubject(e.target.value);
  };

  const filteredMcqs = mcqs.filter((mcq) => !filterSubject || mcq.subject === filterSubject);

  return (
    <div>
      <h2>Filter MCQs by Subject</h2>
      <select value={filterSubject} onChange={handleFilterChange}>
        <option value="">All Subjects</option>
        {subjects.map((subject, index) => (
          <option key={index} value={subject}>
            {subject}
          </option>
        ))}
      </select>

      <h2>MCQs</h2>
      {filteredMcqs.length > 0 ? (
        filteredMcqs.map((mcq, index) => ( // Use index to display question number
          <div key={mcq.id} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
            <h3>
              {index + 1}. {/* Display question number */}
              <span dangerouslySetInnerHTML={{ __html: mcq.question }} />
            </h3>
            <ol>
              {mcq.options.map((option, optionIndex) => (
                <li key={optionIndex}>{option}</li>
              ))}
            </ol>
            <button
              onClick={() => alert(`Correct Answer: ${mcq.answer}\nExplanation: ${mcq.explanation || 'No explanation provided.'}`)}
            >
              Show Answer
            </button>
          </div>
        ))
      ) : (
        <p>No MCQs available for the selected subject.</p>
      )}
    </div>
  );
};

export default MCQs;
